import { useEffect, useState } from "react";
import { getDoc, doc } from "firebase/firestore";
import { db } from "../../config/firebase";

const useGetUserById = (userId) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        const userRef = doc(db, "users", userId);
        const userSnapshot = await getDoc(userRef); 

        if (!isMounted) return;

        if (userSnapshot.exists()) {
          setUser(userSnapshot.data());
        } else {
          setError(new Error(`No user found for ID: ${userId}`));
        }
      } catch (err) {
        if (isMounted) setError(new Error(`Error fetching user with ID: ${userId}. Error: ${err.message}`));
      } finally {
        if (isMounted) setLoading(false);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [userId]);

  return { user, loading, error };
};

export default useGetUserById;
