import React, { useState, useEffect, useRef } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { db, auth } from "../config/firebase";
import {
  getDocs,
  collection,
  doc,
  getDoc,
  addDoc,
  Timestamp,
  setDoc
} from "firebase/firestore";
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  createUserWithEmailAndPassword,
} from "firebase/auth";

const DeliveryDetails = () => {
  const { bikeId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { startDate, endDate } = location.state || {};
  const [bikeData, setBikeData] = useState(null);
  const [companyData, setCompanyData] = useState(null);
  const [deliveryAddress, setDeliveryAddress] = useState("");
  const [deliveryTime, setDeliveryTime] = useState("");
  const [collectionTime, setCollectionTime] = useState("");
  const [numHelmets, setNumHelmets] = useState(2);
  const [requestedDelivery, setRequestedDelivery] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isExistingUser, setIsExistingUser] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [modalStep, setModalStep] = useState(1);
  const modalRef = useRef(null);

  const formatPrice = (price) => {
    const parts = price.toFixed(0).split(".");
    const formatted = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return formatted;
  };

  useEffect(() => {
    const fetchBikeData = async () => {
      try {
        const bikeDocRef = doc(db, "bikes", bikeId);
        const bikeDocSnap = await getDoc(bikeDocRef);
        if (bikeDocSnap.exists()) {
          const bike = bikeDocSnap.data();
          setBikeData(bike);

          const companyDocRef = doc(db, "companies", bike.companyID);
          const companyDocSnap = await getDoc(companyDocRef);
          if (companyDocSnap.exists()) {
            const company = companyDocSnap.data();
            setCompanyData(company);
          }
        }
      } catch (error) {
        console.error("Error fetching bike or company data: ", error);
      }
    };

    fetchBikeData();

    onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    });

    // Scroll to top when the component is first rendered
    window.scrollTo(0, 0);
  }, [bikeId]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!requestedDelivery && requestedDelivery !== false) {
      alert("Please select a delivery option.");
      return;
    }

    if (requestedDelivery) {
      if (!deliveryAddress) {
        alert("Please enter a delivery address.");
        return;
      }
      if (!deliveryTime) {
        alert("Please enter a delivery time.");
        return;
      }
    } else {
      if (!collectionTime) {
        alert("Please enter a collection time.");
        return;
      }
    }

    if (isLoggedIn) {
      navigate("/confirm", {
        state: {
          startDate,
          endDate,
          deliveryAddress,
          deliveryTime,
          collectionTime,
          numHelmets,
          requestedDelivery,
          bikeData,
          companyData,
          totalPrice,
          bikeId,
        },
      });
    } else {
      resetModal();
      setShowModal(true);
    }
  };

  const handleEmailSubmit = async () => {
    try {
      const signInMethods = await fetchSignInMethodsForEmail(auth, email);
      if (signInMethods.length > 0) {
        setIsExistingUser(true);
      } else {
        setIsExistingUser(false);
      }
      setModalStep(2);
    } catch (error) {
      console.error("Error checking email: ", error);
    }
  };

  const handleLogin = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      closeAndResetModal();
      navigate("/confirm", {
        state: {
          startDate,
          endDate,
          deliveryAddress,
          deliveryTime,
          collectionTime,
          numHelmets,
          requestedDelivery,
          bikeData,
          companyData,
          totalPrice,
          bikeId,
        },
      });
    } catch (error) {
      alert("Wrong password. Please try again");
      console.error("Error logging in: ", error);
    }
  };

  const fullName = `${firstName} ${lastName}`;

  const handleSignUp = async () => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      const userDocRef = doc(db, "users", user.uid);

      await setDoc(userDocRef, {
        name: fullName,
        email: email,
        isHost: false,
        signedUp: Timestamp.now(),
      });

      closeAndResetModal();
      navigate("/confirm", {
        state: {
          startDate,
          endDate,
          deliveryAddress,
          deliveryTime,
          collectionTime,
          numHelmets,
          requestedDelivery,
          bikeData,
          companyData,
          totalPrice,
          bikeId
        },
      });
    } catch (error) {
      console.error("Error signing up: ", error);
    }
  };

  const calculateTotalPrice = (dailyPrice, weeklyPrice, monthlyPrice) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const totalDays = (end - start) / (1000 * 60 * 60 * 24);
    const fullMonths = Math.floor(totalDays / 30);
    const fullWeeks = Math.floor((totalDays - fullMonths * 30) / 7);
    const fullDays = totalDays - fullMonths * 30 - fullWeeks * 7;

    if (totalDays < 7) {
      return dailyPrice * totalDays;
    } else if (totalDays >= 7 && totalDays < 30) {
      return fullWeeks * weeklyPrice + (totalDays - fullWeeks * 7) * dailyPrice;
    } else if (totalDays >= 30) {
      return (
        fullMonths * monthlyPrice +
        fullWeeks * weeklyPrice +
        fullDays * dailyPrice
      );
    }
    return monthlyPrice;
  };

  const options = { weekday: "long", day: "numeric", month: "long" };
  const formattedStartDate = new Date(startDate).toLocaleDateString(
    "en-US",
    options
  );
  const formattedEndDate = new Date(endDate).toLocaleDateString(
    "en-US",
    options
  );
  const totalPrice = bikeData
    ? calculateTotalPrice(
        parseFloat(bikeData.dailyPrice),
        parseFloat(bikeData.weeklyPrice),
        parseFloat(bikeData.monthlyPrice)
      )
    : 0;
  const totalDays =
    (new Date(endDate) - new Date(startDate)) / (1000 * 60 * 60 * 24);
  const pricePerDay = totalDays > 0 ? totalPrice / totalDays : 0;

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      closeAndResetModal();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const closeAndResetModal = () => {
    setShowModal(false);
    resetModal();
  };

  const resetModal = () => {
    setEmail("");
    setPassword("");
    setIsExistingUser(false);
    setFirstName("");
    setLastName("");
    setModalStep(1);
  };

  const calculateNumberOfDays = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const diffTime = Math.abs(endDate - startDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const numberOfDays = calculateNumberOfDays(startDate, endDate);

  return (
    <div className="min-h-screen bg-white flex">
      <div className="flex-grow max-w-7xl mx-auto flex flex-col lg:flex-row">
        {/* Selection Area */}
        <div className="lg:w-2/3 p-8 pb-32 lg:pb-8">
          <div className="flex flex-row">
            <p>Delivery &gt;&nbsp;</p>
            <p className="block text-[#757676]">Confirm</p>
          </div>

          <h1 className="text-2xl font-bold mb-6">Select delivery option</h1>

          <form>
            <div className="mb-4">
              <label className="block text-[#757676]">Delivery Option</label>
              <select
                className="w-full mt-2 p-2 border border-gray-300 rounded-lg"
                value={requestedDelivery}
                onChange={(e) =>
                  setRequestedDelivery(e.target.value === "true")
                }
              >
                <option value="">Select an option</option>
                <option value="false">Collect from shop</option>
                <option value="true">Deliver to me</option>
              </select>
            </div>
            {requestedDelivery === true && (
              <>
                <div className="mb-4">
                  <label className="block text-[#757676]">
                    Delivery Address
                  </label>
                  <input
                    type="text"
                    className="w-full mt-2 p-2 border border-gray-300 rounded-lg"
                    value={deliveryAddress}
                    onChange={(e) => setDeliveryAddress(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-[#757676]">Delivery Time</label>
                  <input
                    type="time"
                    className="w-full mt-2 p-2 border border-gray-300 rounded-lg"
                    value={deliveryTime}
                    onChange={(e) => setDeliveryTime(e.target.value)}
                    required
                  />
                </div>
              </>
            )}
            {requestedDelivery === false && (
              <div className="mb-4">
                <label className="block text-[#757676]">Collection Time</label>
                <input
                  type="time"
                  className="w-full mt-2 p-2 border border-gray-300 rounded-lg"
                  value={collectionTime}
                  onChange={(e) => setCollectionTime(e.target.value)}
                  required
                />
              </div>
            )}
          </form>
        </div>

        {/* Details Area */}
        <div className="lg:w-1/3 p-6 rounded-lg lg:ml-6 sticky bottom-0 lg:static w-full lg:w-auto mt-auto lg:mt-6 lg:mb-6 flex flex-col justify-between bg-[#F6F5F1]">
          <div>
            {bikeData && (
              <div>
                <p className="font-bold hidden lg:block">
                  {bikeData.name} {bikeData.displacement}cc {bikeData.year}
                </p>
                <div className="hidden lg:block">
                  {companyData && (
                    <p className="text-sm text-[#757676]">
                      {companyData.companyName}
                    </p>
                  )}
                  <p className="block text-sm text-[#757676]">
                    {formattedStartDate} - {formattedEndDate}
                  </p>
                  <div className="my-4 border-t border-gray-300"></div>
                  <p className="block text-sm mb-1">
                    Rp{formatPrice(totalPrice / numberOfDays)} x {numberOfDays}{" "}
                    days
                  </p>
                  <p className="block font-bold">
                    Total: Rp{formatPrice(totalPrice)}
                  </p>
                </div>
              </div>
            )}
            <div>
              <div className="flex justify-between items-center lg:hidden">
                <div>
                  <p className="block font-bold">Rp{formatPrice(totalPrice)}</p>
                  <p className="block text-[#757676] text-sm">
                    {formattedStartDate} - {formattedEndDate}
                  </p>
                </div>
                <button
                  type="button"
                  className="bg-[#ffe450] text-black py-2 px-4 rounded hover:bg-[#e6cc3d] transition duration-300 shadow-md font-bold"
                  onClick={handleSubmit}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
          <button
            type="button"
            className="hidden lg:block w-full bg-[#ffe450] text-black py-2 px-4 rounded-lg hover:bg-[#e6cc3d] transition duration-300 mt-4 shadow-md font-bold"
            onClick={handleSubmit}
          >
            Continue
          </button>
        </div>

        {/* Modal for email input */}
        {showModal && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div
              ref={modalRef}
              className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md"
            >
              {modalStep === 1 && (
                <>
                  <h2 className="text-2xl">Log in or sign up</h2>
                  <p className="mb-2">
                    Log in or sign up to complete your booking
                  </p>
                  <input
                    type="email"
                    placeholder="Email"
                    className="w-full p-2 mb-4 border border-gray-300 rounded-lg"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <button
                    onClick={handleEmailSubmit}
                    className="w-full bg-[#ffe450] text-black py-2 px-4 rounded-lg hover:bg-[#e6cc3d] transition duration-300 hover:bg-[#e6cc3d] font-bold"
                  >
                    Continue
                  </button>
                </>
              )}
              {modalStep === 2 && (
                <>
                  {isExistingUser ? (
                    <>
                      <h2 className="text-2xl">Log in</h2>
                      <p className="mb-2">Enter your password to log in</p>
                      <input
                        type="password"
                        placeholder="Password"
                        className="w-full p-2 mb-4 border border-gray-300 rounded-lg"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <button
                        onClick={handleLogin}
                        className="w-full bg-[#ffe450] text-black py-2 px-4 rounded-lg hover:bg-[#e6cc3d] transition duration-300 hover:bg-[#e6cc3d] font-bold"
                      >
                        Log In
                      </button>
                    </>
                  ) : (
                    <>
                      <h2 className="text-2xl">Sign up</h2>
                      <p className="mb-2">Create an account to continue</p>
                      <input
                        type="text"
                        placeholder="First Name"
                        className="w-full p-2 mb-4 border border-gray-300 rounded-lg"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                      <input
                        type="text"
                        placeholder="Last Name"
                        className="w-full p-2 mb-4 border border-gray-300 rounded-lg"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                      <input
                        type="password"
                        placeholder="Password"
                        className="w-full p-2 mb-4 border border-gray-300 rounded-lg"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <button
                        onClick={handleSignUp}
                        className="w-full bg-[#ffe450] text-black py-2 px-4 rounded-lg hover:bg-[#e6cc3d] transition duration-300 hover:bg-[#e6cc3d] font-bold"
                      >
                        Continue
                      </button>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DeliveryDetails;
