import React, { useState, useEffect } from "react";
import axios from "axios";
import Flexbike_logo from "../assets/Flexbike_logo.svg";
import "../tailwind.css"; // Импортируйте стили Tailwind CSS

const App = () => {
  const [htmlContent, setHtmlContent] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("bahasa");
  const fetchHtmlContent = (language) => {
    const url =
      language === "bahasa"
        ? "/GuidetohostingwithFlexbike_Bahasa_.html"
        : "/GuidetohostingwithFlexbike_English_.html";

    axios
      .get(url)
      .then((response) => {
        setHtmlContent(response.data);
      })
      .catch((error) => {
        console.error("Ошибка загрузки HTML:", error);
      });
  };

  useEffect(() => {
    fetchHtmlContent(selectedLanguage);
  }, [selectedLanguage]);

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
  };

  return (
    <div className="flex flex-col justify-center items-center p-4 relative">
      <img src={Flexbike_logo} alt="FLEXBIKE" style={{ width: "300px" }} />
      <div className="fixed bottom-4 right-4 z-50">
        <button
          className={`mr-2 px-4 py-2 bg-ffe450 text-black rounded ${
            selectedLanguage === "bahasa" ? "opacity-100" : "opacity-50"
          }`}
          onClick={() => handleLanguageChange("bahasa")}
        >
          Bahasa
        </button>
        <button
          className={`px-4 py-2 bg-ffe450 text-black rounded ${
            selectedLanguage === "english" ? "opacity-100" : "opacity-50"
          }`}
          onClick={() => handleLanguageChange("english")}
        >
          English
        </button>
      </div>
      <div className="w-full lg:w-3/4 xl:w-3/6 mt-10 mb-10">
        <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
      </div>
    </div>
  );
};

export default App;
