import React from "react";
import { useNavigate } from "react-router-dom";
import useCompanyBikes from "./hooks/useCompanyBikes";

const Company = (company) => {
  const navigate = useNavigate();
  const _id = company.company.companyName;
  const companyId = company.company.companyID;
  const idString = (_id) => {
    return String(_id).toLowerCase().split(" ").join("");
  };
  const rootId = idString(_id);

  const { companyBikes } = useCompanyBikes(companyId);

  const handleDetails = () => {
    navigate(`/book-now/${rootId}-${companyId}`, { // add companyId to URL
      state: {
        item: { ...company, company: { ...company.company, companyID: companyId } },
      },
    });
  };

  const bikeCountText = companyBikes.length === 0 ? "No bikes available" :
    companyBikes.length === 1 ? "1 bike" : `${companyBikes.length} bikes`;

  return (
    <div
      onClick={handleDetails}
      className="relative p-4 border rounded-lg cursor-pointer bg-white 
      transition-all duration-200 shadow-md hover:shadow-lg 
      transform hover:-translate-y-1 hover:scale-105"
    >
      <div className="text-xl font-semibold mb-2">
        {company.company.companyName}
      </div>
      <div className="text-gray-600 mb-2">📍 {company.company.area}</div>
      <div>{`🛵 ${bikeCountText}`}</div>
      <div className="absolute top-2 right-2 text-gray-400"></div>
    </div>
  );
};

export default Company;
