import { useEffect, useState } from "react";
import { getDoc, doc } from "firebase/firestore";
import { db } from "../../config/firebase";

const useGetBikeById = (bikeId) => {
  const [bike, setBike] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    let isMounted = true;

    if (!bikeId) {
      setError(new Error("Bike ID is not provided."));
      setLoading(false);
      return;
    }

    const fetchData = async () => {
      try {
        const bikesRef = doc(db, "bikes", bikeId);
        const bikeSnapshot = await getDoc(bikesRef);

        if (!isMounted) return;

        if (bikeSnapshot.exists()) {
          setBike(bikeSnapshot.data());
        } else {
          setError(new Error(`No document found for ID: ${bikeId}`));
        }
      } catch (err) {
        if (isMounted) setError(new Error(`Error fetching bike with ID: ${bikeId}. Error: ${err.message}`));
      } finally {
        if (isMounted) setLoading(false);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [bikeId]);

  return { bike, loading, error };
};

export default useGetBikeById;
