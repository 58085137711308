import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FaWhatsapp, FaDownload } from "react-icons/fa";

const RequestSent = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { companyData, startDate, bikeData } = location.state || {};

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { weekday: 'long', day: 'numeric', month: 'short' };
    return date.toLocaleDateString('en-US', options);
  };

  useEffect(() => {
    const handlePopState = (event) => {
      navigate("/", { replace: true });
    };

    window.history.replaceState(null, document.title, window.location.pathname);
    window.history.pushState(null, document.title, "/");

    window.addEventListener("popstate", handlePopState);

    window.scrollTo(0, 0);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate]);

  const handleWhatsAppClick = () => {
    if (companyData && companyData.companyWhatsappNumber) {
      const formattedDate = formatDate(startDate);
      const message = encodeURIComponent(
        `Hi. I made a booking on Flexbike for your ${bikeData.name}, starting on ${formattedDate}`
      );
      window.open(
        `https://wa.me/${companyData.companyWhatsappNumber}?text=${message}`,
        "_blank"
      );
    }
  };

  return (
    <div className="min-h-screen flex flex-col lg:flex-row bg-white">
      <div className="lg:w-2/3 p-8">
        <h1 className="text-2xl font-bold mb-6 mt-6">Booking request sent</h1>
        <h2 className="text-lg font-semibold mb-10">
          Download the app to receive updates on your booking and chat with the
          owner
        </h2>
        <div className="flex flex-col items-start space-y-4">
          <a
            href="download"
            className="inline-flex items-center bg-primary rounded-full py-3 px-8 text-base font-bold text-dark transition duration-500 ease-in-out hover:bg-dark hover:text-white shadow-md hover:shadow-lg"
          >
            <FaDownload className="mr-2" />
            Download app
          </a>
          <button
            onClick={handleWhatsAppClick}
            className="inline-flex items-center bg-green-500 hover:bg-green-600 text-white font-bold py-3 px-8 rounded-full transition duration-300 ease-in-out shadow-md hover:shadow-lg"
          >
            <FaWhatsapp className="mr-2" />
            Or chat on WhatsApp
          </button>
        </div>
      </div>
    </div>
  );
};

export default RequestSent;
