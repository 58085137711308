import React from "react";
import { useNavigate } from "react-router-dom";
import { Pagination, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";

const FeaturePill = ({ text }) => (
  <span className="bg-gray-200 text-gray-700 px-2 py-1 rounded-full text-xs">
    {text}
  </span>
);

const BikeCard = ({ bike, deliveryAvailable, startDate, endDate }) => {
  const navigate = useNavigate();

  const formatPrice = (price) => {
    const parts = price.toFixed(0).split(".");
    const formatted = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return formatted;
  };

  const handleCardClick = () => {
    navigate(`/delivery/${bike.id}`, {
      state: {
        bikeId: bike.id,
        startDate,
        endDate,
      },
    });
  };

  return (
    <div
      className="bike-card rounded-lg py-4 relative flex flex-col cursor-pointer"
      onClick={handleCardClick}
    >
      {bike.images && bike.images.length > 0 ? (
        <div className="relative w-full h-60 overflow-hidden rounded-lg shadow-md">
          <Swiper
            modules={[Pagination, A11y]}
            spaceBetween={0}
            slidesPerView={1}
            pagination={{ clickable: true }}
            className="w-full h-full"
          >
            {bike.images.map((image, index) => (
              <SwiperSlide key={index} className="w-full h-full">
                <div className="w-full h-full">
                  <img
                    src={image}
                    alt={`${bike.name} - Image ${index + 1}`}
                    className="w-full h-full object-cover"
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <style>
            {`
              .swiper-pagination-bullet {
                background: #FFF;
                opacity: 0.5;
              }

              .swiper-pagination-bullet-active {
                background: #FFF;
                opacity: 1;
              }

              .swiper, .swiper-wrapper, .swiper-slide {
                height: 100% !important;
              }
            `}
          </style>
        </div>
      ) : (
        <div className="w-full h-60 bg-gray-200 rounded-lg flex items-center justify-center">
          <span className="text-gray-500">No image available</span>
        </div>
      )}
      <div className="mt-2 flex flex-col justify-end">
        <div className="flex justify-between items-center">
          <p className="text-lg font-bold">
            {bike.name} {bike.displacement}cc {bike.year}
          </p>
        </div>

        <div className="flex justify-between items-center mt-0">
          <p className="text-[#757676]">
            <strong>Rp{formatPrice(parseFloat(bike.pricePerDay))}</strong> day
          </p>
          <p className="text-[#757676] underline">
            Rp{formatPrice(bike.totalPrice)} total
          </p>
        </div>
        <div className="flex flex-wrap gap-1 mt-2">
          {bike.surfRack && <FeaturePill text="🤙 Surf Rack" />}
          {bike.keyless && <FeaturePill text="🔑 Keyless" />}
          {bike.ABS && <FeaturePill text="🛞 ABS" />}
        </div>
      </div>
    </div>
  );
};

export default BikeCard;
