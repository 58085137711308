import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../config/firebase";
import { signOut } from "firebase/auth";
import { FaUserCircle } from "react-icons/fa";
import { useAuthState } from "react-firebase-hooks/auth";

const Navbar = () => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate("/");
      window.location.reload(); // Ensure the page reloads to clear any cached state
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (!user) {
    return null; // Do not render Navbar if user is not authenticated
  }

  return (
    <div className="fixed top-0 right-0 p-2">
      <FaUserCircle
        className="text-2xl cursor-pointer"
        onClick={() => setDropdownVisible(!dropdownVisible)}
      />
      {dropdownVisible && (
        <div
          ref={dropdownRef}
          className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-lg shadow-lg"
        >
          <button
            className="w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
            onClick={handleSignOut}
          >
            Sign Out
          </button>
        </div>
      )}
    </div>
  );
};

export default Navbar;
