import React from "react";
import Modal from "react-modal";
import QRCode from "qrcode.react";

// This is necessary for the accessibility of modals
Modal.setAppElement("#root");

const QRModal = ({ isOpen, qrValue, onRequestClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="QR Code"
      className="absolute top-1/2 left-1/2 bg-white
      transform -translate-x-1/2 -translate-y-1/2 w-64 h-64 
      rounded-lg flex flex-col items-center justify-center pt-4"
      overlayClassName="fixed inset-0 bg-black bg-opacity-75"
    >
      <button
        onClick={onRequestClose}
        className="absolute top-0.5 right-2 text-gray-500 hover:text-gray-700 text-2xl"
      >
        &times;
      </button>
      <QRCode value={qrValue} size={150} bgColor="transparent" />
      <p className="mt-4 text-center text-black font-semibold">
        Scan with phone camera
      </p>
    </Modal>
  );
};

export default QRModal;
