import React, { useState, useEffect } from "react";
import { collection, query, where, getDocs, orderBy } from "firebase/firestore";
import { db } from "../config/firebase";
import { calculateAverageReviewData } from "../utils/helpers";
import { MdStar } from "react-icons/md";

const Reviews = ({ companyID, setReviewAverage, setReviewCount }) => {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showAllReviews, setShowAllReviews] = useState(false);

  useEffect(() => {
    const fetchReviews = async () => {
      setLoading(true);
      setError(null);

      try {
        const reviewsQuery = query(
          collection(db, "reviews"),
          where("companyID", "==", companyID),
          orderBy("createdAt", "desc")
        );

        const reviewsSnapshot = await getDocs(reviewsQuery);
        const fetchedReviews = reviewsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setReviews(fetchedReviews);

        // Calculate and set review average and count
        const reviewData = calculateAverageReviewData(fetchedReviews);
        setReviewAverage(reviewData.average);
        setReviewCount(reviewData.count);
      } catch (error) {
        setError("Error fetching reviews.");
        console.error("Error fetching reviews:", error);
      } finally {
        setLoading(false);
      }
    };

    if (companyID) {
      fetchReviews();
    }
  }, [companyID, setReviewAverage, setReviewCount]);

  if (loading) {
    return <p>Loading reviews...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  if (reviews.length === 0) {
    return null;
  }

  const visibleReviews = showAllReviews ? reviews : reviews.slice(0, 2);

  return (
    <div className="flex flex-col h-full mt-5">
      <h1 className="text-xl font-bold">Reviews</h1>
      {visibleReviews.map((review, index) => (
        <div
          key={review.id}
          className={`my-4 relative ${
            index === 1 && !showAllReviews ? "overflow-hidden" : ""
          }`}
          style={
            index === 1 && !showAllReviews
              ? { maxHeight: "150px" } // Adjust this value as needed
              : {}
          }
        >
          <div className="border border-gray-300 rounded-lg p-4 bg-eggshell">
            <div className="flex items-center mb-2">
              <strong className="mr-2">{review.riderName}</strong>
              <MdStar size={16} className="text-gray-500" />
              <p>{review.rating}</p>
            </div>
            <p>{review.text}</p>
            <p className="text-xs text-gray-500 mt-2">
              {new Date(review.createdAt?.seconds * 1000).toLocaleDateString(
                "en-GB",
                {
                  month: "long",
                  year: "numeric",
                }
              )}
            </p>
          </div>
          {index === 1 && !showAllReviews && (
            <div
              className="absolute top-0 left-0 right-0 bottom-0 pointer-events-none"
              style={{
                background:
                  "linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 70%, rgba(255,255,255,1) 100%)",
              }}
            ></div>
          )}
        </div>
      ))}
      {reviews.length > 2 && !showAllReviews && (
        <button
          className="mt-4 px-4 py-2 bg-eggshell text-dark rounded-lg hover:bg-darkEggshell"
          onClick={() => setShowAllReviews(true)}
        >
          Show more reviews
        </button>
      )}
    </div>
  );
};

export default Reviews;
