import React, { useEffect, useState } from "react";
import Company from "../components/Company";
import { useLoaderData } from "react-router-dom";
import LoadingSpinner from "../components/LoadingSpinner";

const CompanyLinks = () => {
  const [companyData, setCompanyData] = useState([]);
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState("all");
  const [isLoading, setIsLoading] = useState(true); // New state for tracking loading status
  const data = useLoaderData();

  useEffect(() => {
    setIsLoading(true); // Set loading status to true when data starts loading

    if (data && data.companyData) {
      setCompanyData(data.companyData);
      const uniqueAreas = [
        ...new Set(data.companyData.map((item) => item.area)),
      ];
      const filteredAreas = uniqueAreas.filter((area) => area !== undefined); // Filter out undefined values
      setAreas(["Anywhere", ...filteredAreas]);

      // Restore selected area from local storage
      const savedArea = window.localStorage.getItem("selectedArea");
      if (savedArea) {
        setSelectedArea(savedArea);
      } else if (filteredAreas.length > 0) {
        setSelectedArea(filteredAreas[0]); // Set the first area if no saved area
      }
    } else {
      console.error("Failed to load company data", data);
    }

    setIsLoading(false); // Set loading status to false when data is loaded
  }, [data]);

  useEffect(() => {
    // Save selected area to local storage whenever it changes
    window.localStorage.setItem("selectedArea", selectedArea);
  }, [selectedArea]);

  if (isLoading) {
    return <LoadingSpinner />; // Show a loading message while data is loading
  }

  const filteredCompanies =
    selectedArea === "Anywhere"
      ? companyData
      : companyData.filter((item) => item.area === selectedArea);

  return (
    <div className="min-h-screen max-w-screen-xl mx-auto py-10 px-4 sm:px-6 lg:px-8">
      <div className="flex justify-center mb-16 pt-5">
        <div className="relative w-64">
          {areas.length > 0 && (
            <select
              id="areaSelect" // Add an id attribute
              className="appearance-none w-full bg-white border border-gray-400
               hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight 
               focus:outline-none focus:shadow-outline"
              value={selectedArea}
              onChange={(e) => setSelectedArea(e.target.value)}
            >
              {areas
                .filter((area) => area !== "")
                .map((area, index) => (
                  <option key={`${area}-${index}`} value={area}>
                    {area}
                  </option>
                ))}
            </select>
          )}
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3 text-gray-700">
            <svg
              width="16"
              height="14"
              viewBox="0 0 16 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.00006 14L0.205836 0.500002L15.7943 0.500002L8.00006 14Z"
                fill="#D9D9D9"
              />
            </svg>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
        {filteredCompanies.map((item) => (
          <Company
            key={`${item.companyName}-${item.companyID}`}
            company={item}
          />
        ))}
      </div>
    </div>
  );
};

export default CompanyLinks;
