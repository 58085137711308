import { useEffect, useState } from "react";
import { getDocs, collection, query, where } from "firebase/firestore";
import { db } from "../../config/firebase";

const useGetCompanyById = (companyId) => {
  const [company, setCompany] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const companiesCollectionRef = collection(db, "companies");
        const q = query(companiesCollectionRef, where("companyID", "==", companyId));
        const companyData = await getDocs(q);
        const company = companyData.docs.map((doc) => doc.data())[0];
        setCompany(company);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchData();
  }, [companyId]);

  return { company, loading, error };
};

export default useGetCompanyById;
