import { useEffect, useState } from "react";
import { getDoc, doc } from "firebase/firestore";
import { db } from "../../config/firebase";

const useGetBookingById = (bookingId) => {
  const [booking, setBooking] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        const bookingRef = doc(db, "bookings", bookingId);
        const bookingSnapshot = await getDoc(bookingRef);

        if (!isMounted) return;

        if (bookingSnapshot.exists()) {
          setBooking(bookingSnapshot.data());
        } else {
          setError(new Error(`No document found for ID: ${bookingId}`));
        }
      } catch (err) {
        if (isMounted) setError(new Error(`No document found for the provided ID: ${bookingId}. Error: ${err.message}`));
      } finally {
        if (isMounted) setLoading(false);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [bookingId]);

  return { booking, loading, error };
};

export default useGetBookingById;
