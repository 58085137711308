import { collection, getDocs } from "firebase/firestore";
import React from "react";
import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import { db } from "../src/config/firebase";
import BookingPage from "./pages/BookingPage";
import CompanyCard from "./pages/CompanyCard";
import CompanyLinks from "./pages/CompanyLinks";
import CoverageMap from "./pages/CoverageMap";
import DownloadPage from "./pages/DownloadPage";
import ForBusinessPage from "./pages/ForBusinessPage";
import Home from "./pages/Home";
import HostGuide from "./pages/HostGuide";
import LinkedBankConfirmationPage from "./pages/LinkedBankConfirmationPage";
import TermsAndConditionsPage from "./pages/TermsAndConditionsPage";
import DeliveryDetails from "./pages/DeliveryDetailsPage";
import Confirm from "./pages/ConfirmPage";
import NavBar from "./components/NavBar";
import ProtectedRoute from "./components/ProtectedRoute";
import RequestSent from "./pages/RequestSent";
import BikePage from "./pages/BikePage";

const Layout = () => {
  return (
    <div>
      <NavBar />
      <Outlet />
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/book-now",
        element: <CompanyLinks />,
        loader: async () => {
          const companyCollectionRef = collection(db, "companies");
          const data = await getDocs(companyCollectionRef);
          const companies = data.docs.map((doc) => doc.data());
          return { companyData: companies };
        },
      },
      {
        path: "/book-now/:id",
        element: <CompanyCard />,
      },
      {
        path: "/bike/:id",
        element: <BikePage />,
      },
      {
        path: "/booking/:bookingId",
        element: <BookingPage />,
      },
      {
        path: "/hostguide",
        element: <HostGuide />,
      },
      {
        path: "/map",
        element: <CoverageMap />,
        loader: async () => {
          const companyCollectionRef = collection(db, "companies");
          const data = await getDocs(companyCollectionRef);
          const companies = data.docs.map((doc) => doc.data());
          return { companyData: companies };
        },
      },
      {
        path: "/download",
        element: <DownloadPage />,
      },
      {
        path: "/for-business",
        element: <ForBusinessPage />,
      },
      {
        path: "/linked-bank-confirmation",
        element: <LinkedBankConfirmationPage />,
      },
      {
        path: "/terms",
        element: <TermsAndConditionsPage />,
      },
      {
        path: "/delivery/:bikeId",
        element: <DeliveryDetails />,
      },
      {
        path: "/confirm",
        element: <ProtectedRoute element={<Confirm />} />,
      },
      {
        path: "/request-sent",
        element: <ProtectedRoute element={<RequestSent />} />,
      },
    ],
  },
]);

function App() {
  return (
    <div className="bg-eggshell min-h-screen">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
