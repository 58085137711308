import React, { useEffect, useMemo, useState } from "react";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import LoadingSpinner from "../components/LoadingSpinner";
import { db } from "../config/firebase";
import { getDocs, collection, query, where } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { MdStar, MdVerified } from "react-icons/md";
import { BsFillPatchQuestionFill } from "react-icons/bs";

function Map({ markers, selectedMarker, onSelectMarker }) {
  const center = useMemo(() => ({ lat: -8.404485, lng: 115.105124 }), []);

  const getMarkerIcon = (marker) => {
    if (marker.verified) {
      return "http://maps.google.com/mapfiles/ms/icons/blue-dot.png";
    } else if (marker.claimed && !marker.verified) {
      return "http://maps.google.com/mapfiles/ms/icons/green-dot.png";
    } else {
      return "http://maps.google.com/mapfiles/ms/icons/yellow-dot.png";
    }
  };

  return (
    <GoogleMap zoom={10} center={center} mapContainerClassName="map-container">
      {markers.map(
        (marker, index) =>
          (marker.claimed || marker.verified) && (
            <Marker
              key={index}
              position={{ lat: marker.lat, lng: marker.lng }}
              onClick={() => onSelectMarker(marker)}
              icon={getMarkerIcon(marker)}
            >
              {selectedMarker === marker && (
                <InfoWindow onCloseClick={() => onSelectMarker(null)}>
                  <div style={{ maxWidth: "200px" }}>
                    <h2 className="font-bold mb-2 mr-2">
                      {marker.companyName}
                    </h2>
                    {marker.verified && (
                      <div className="flex flex-row mb-2">
                        <MdVerified size={16} className="text-[#0000FF] mr-2" />
                        <p className="text-[#0000FF]">Verified</p>
                      </div>
                    )}
                    {!marker.claimed && (
                      <div className="flex flex-row mb-2">
                        <BsFillPatchQuestionFill
                          size={16}
                          className="text-[#757676] mr-2"
                        />
                        <p className="text-[#757676]">Unclaimed</p>
                      </div>
                    )}
                    <p className="text-[#757676] mb-2">{marker.address}</p>
                    <div className="flex items-center mb-2">
                      <MdStar size={16} className="text-[#757676]" />
                      {marker.reviewCount ? (
                        <p className="text-[#757676] ml-1">
                          {marker.reviewAverage} ({marker.reviewCount} reviews)
                        </p>
                      ) : (
                        <p className="text-[#757676] ml-1">No reviews yet</p>
                      )}
                    </div>
                    <button
                      onClick={() => window.open(marker.bookingUrl, "_blank")}
                      style={{
                        padding: "10px 20px",
                        color: "black",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer",
                        textDecoration: "none",
                        backgroundColor: "#ffe450",
                        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                        maxWidth: "100%",
                      }}
                    >
                      Book Now
                    </button>
                    <button
                      onClick={() =>
                        window.open(
                          `https://wa.me/${marker.companyWhatsappNumber}`,
                          "_blank"
                        )
                      }
                      style={{
                        padding: "10px 20px",
                        color: "white",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer",
                        textDecoration: "none",
                        backgroundColor: "green",
                        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                        maxWidth: "100%",
                        marginLeft: 10,
                      }}
                    >
                      Chat
                    </button>
                    <p className="text-[#757676] mt-2 text-xs">Company ID: {marker.companyID}</p>
                  </div>
                </InfoWindow>
              )}
            </Marker>
          )
      )}
    </GoogleMap>
  );
}

const CoverageMap = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCN8d_wYOrNewLnl2jwCdx2WDRkEquAzAA",
  });

  const [markers, setMarkers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedMarker, setSelectedMarker] = useState(null);

  const navigate = useNavigate();

  const handleDetails = (rootId, companyId, company) => {
    navigate(`/book-now/${rootId}-${companyId}`, {
      state: {
        item: {
          ...company,
          company: { ...company.company, companyID: companyId },
        },
      },
    });
  };

  useEffect(() => {
    async function fetchData() {
      const companiesRef = collection(db, "companies");
      const querySnapshot = await getDocs(companiesRef);
      const markersData = [];
      for (const doc of querySnapshot.docs) {
        const companyData = doc.data();
        const companyName = companyData.companyName;
        const companyId = companyData.companyID;
        const idString = (companyName) => {
          return String(companyName)
            .toLowerCase()
            .split(" ")
            .join("")
            .replace(/\//g, "");
        };

        const rootId = idString(companyName);
        const bookingUrl = `https://flexbike.app/book-now/${rootId}-${companyId}`;
        const address = companyData.companyAddress;
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
            address
          )}&key=AIzaSyCN8d_wYOrNewLnl2jwCdx2WDRkEquAzAA`
        );
        if (response.ok) {
          const data = await response.json();
          if (data.status === "OK" && data.results.length === 1) {
            const location = data.results[0].geometry.location;
            if (!isNaN(location.lat) && !isNaN(location.lng)) {
              const reviewsQuery = query(
                collection(db, "reviews"),
                where("companyID", "==", companyId)
              );
              const reviewsSnapshot = await getDocs(reviewsQuery);
              const reviews = reviewsSnapshot.docs.map((doc) => doc.data());
              const reviewCount = reviews.length;
              const reviewAverage =
                reviews.reduce((acc, review) => acc + review.rating, 0) /
                reviewCount;

              markersData.push({
                lat: location.lat,
                lng: location.lng,
                companyName: companyData.companyName,
                bookingUrl: bookingUrl,
                address: address,
                reviewAverage: reviewAverage.toFixed(1),
                reviewCount: reviewCount,
                verified: companyData.isOnboarded,
                claimed: companyData.claimed,
                companyWhatsappNumber: companyData.companyWhatsappNumber,
                companyID: companyId
              });
            }
          } else {
            console.error(`Geocoding failed for address: ${address}`);
          }
        } else {
          console.error(`Geocoding request failed for address: ${address}`);
        }
      }
      setMarkers(markersData);
      setIsLoading(false);
    }
    fetchData();
  }, []);

  if (!isLoaded || isLoading) return <LoadingSpinner />;
  return (
    <Map
      markers={markers}
      selectedMarker={selectedMarker}
      onSelectMarker={setSelectedMarker}
    />
  );
};

export default CoverageMap;
