export const calculateAverageReviewData = (reviews) => {
  if (!reviews || reviews.length === 0) return { average: null, count: 0 };

  let totalScore = 0;

  reviews.forEach((reviewData) => {
    if (reviewData.rating) {
      totalScore += reviewData.rating;
    }
  });

  const average = (totalScore / reviews.length).toFixed(1);

  return {
    average: average,
    count: reviews.length,
  };
};
