import React, { useState } from "react";
import QRModal from "./QRModal";
import { isMobile } from "react-device-detect";
import googleplay from "../assets/googleplay.svg";
import appstore from "../assets/appstore.svg";
import "../assets/fonts/fonts.css";

const StatusPage = ({ companyId, status, cancelReason }) => {
  const [isQRModalOpen, setIsQRModalOpen] = useState(false);
  const [qrValue, setQrValue] = useState("");

  const handleClick = (url) => {
    if (isMobile) {
      window.location.href = url;
    } else {
      setQrValue(url);
      setIsQRModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setIsQRModalOpen(false);
  };

  let message;
  if (status === "confirmed") {
    message = {
      main: "🎉 Booking confirmed!",
      secondary: "get more bookings",
    };
  } else if (status === "declined") {
    message = {
      main: "😔 Booking declined",
      secondary: "get more bookings",
    };
  } else if (status === "cancelled") {
    message = {
      main: "😔 Booking cancelled by rider",
      secondary: "get more bookings",
    };
  } else if (status === "expired") {
    message = {
      main: "⌛ Booking request expired",
      secondary: "get more bookings",
    };
  } else {
    message = {
      main: "Unknown status",
      secondary: "Please contact support",
    };
  }

  return (
    <div className="flex flex-col justify-center items-center text-center text-lg">
      <div className="text-2xl mb-4 font-black">{message.main}</div>
      {status === "expired" ? (
        <div className="text-xl mb-4 font-black">
          We did not get a response within 24 hours
        </div>
      ) : null}
      {status === "cancelled" && cancelReason ? (
        <div className="bg-blue-100 border-blue-500 border rounded-lg p-4 mb-4">
          <h2 className="text-xl font-bold text-blue-700 mb-2">
            Message from customer:
          </h2>
          <p className="text-blue-700">"{cancelReason}"</p>
        </div>
      ) : null}
      <p>Download the app to</p>
      <p className="font-black pb-4">{message.secondary}</p>
      <div className="flex items-center justify-between space-x-4 mb-4">
        <div
          className="cursor-pointer w-32 h-auto"
          onClick={() =>
            handleClick(
              "https://play.google.com/store/apps/details?id=com.flexbike&hl=en"
            )
          }
        >
          <div
            style={{
              borderRadius: "5px",
              overflow: "hidden",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            }}
          >
            <img
              src={googleplay}
              alt="Google Play"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        </div>
        <div
          className="cursor-pointer w-32 h-auto"
          onClick={() =>
            handleClick("https://apps.apple.com/gb/app/flexbike/id6449488641")
          }
        >
          <div
            style={{
              borderRadius: "5px",
              overflow: "hidden",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            }}
          >
            <img
              src={appstore}
              alt="App Store"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        </div>
      </div>
      <QRModal
        isOpen={isQRModalOpen}
        qrValue={qrValue}
        onRequestClose={handleCloseModal}
      />
    </div>
  );
};

export default StatusPage;
