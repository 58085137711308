import { useEffect, useState } from "react";
import { getDocs, collection, query, where } from "firebase/firestore";
import { db } from "../../config/firebase";
import { getCompanyUsers } from "./useCompanyUsers";

const useCompanyBikes = (companyId) => {
  const [companyBikes, setCompanyBikes] = useState([]);
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // fetch bikes
        const bikesCollectionRef = collection(db, "bikes");
        const q = query(bikesCollectionRef, where("companyID", "==", companyId));
        const bikesData = await getDocs(q);
        const bikes = bikesData.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setCompanyBikes(bikes);

        // fetch phone numbers
        const users = await getCompanyUsers(companyId);
        if (users) {
          const phoneNumbers = users.map((user) => user.phoneNumber);
          setPhoneNumbers(phoneNumbers);
        }

        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchData();
  }, [companyId]);

  return { companyBikes, phoneNumbers, loading, error };
};

export default useCompanyBikes;
