import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useGetBookingById from "../components/hooks/useGetBookingById";
import useGetBikeById from "../components/hooks/useGetBikeById";
import useGetCompanyById from "../components/hooks/useGetCompanyById";
import useGetUserById from "../components/hooks/useGetUserById";
import LoadingSpinner from "../components/LoadingSpinner";
import BookingInfo from "../components/BookingInfo";
import QRModal from "../components/QRModal";
import { isMobile } from "react-device-detect";
import googleplay from "../assets/googleplay.svg";
import appstore from "../assets/appstore.svg";
import { doc, updateDoc, serverTimestamp } from "firebase/firestore";
import axios from "axios";
import "../assets/fonts/fonts.css";
import "../styles/style.css";
import { db } from "../config/firebase";
import StatusPage from "../components/StatusPage";
import { Link } from "react-router-dom";

const LinkedBankConfirmationPage = () => {

  return (
    <div className="mx-auto overflow-y-auto overflow-x-hidden items-center justify-center max-w-screen-lg px-4 py-10">
      <div className="text-center text-black font-bold text-4xl mb-8">
        👈
      </div>
      <div className="text-center text-black font-bold text-2xl">
        Please return to Flexbike app
      </div>
    </div>
  );
};

export default LinkedBankConfirmationPage;
