import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// Config file to get access to Firebase
const firebaseConfig = {
  apiKey: "AIzaSyCDaeHs3wqvbPpM7vxseMRKj59uzK4O8Zk",
  authDomain: "project-s-v2.firebaseapp.com",
  projectId: "project-s-v2",
  storageBucket: "project-s-v2.appspot.com",
  messagingSenderId: "918797226270",
  appId: "1:918797226270:web:89d27c4a45200dcaceb90f",
  measurementId: "G-S0K522WYP7",
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const auth = getAuth(app);
