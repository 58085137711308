import React from "react";

const NoBikesMessage = () => (
  <div className="company-container flex justify-center items-center h-screen">
    <div className="text-xl text-gray-600">
      No bikes available for this company.
    </div>
  </div>
);

export default NoBikesMessage;
