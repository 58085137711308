import { collection, getDocs, query, where } from "@firebase/firestore";
import { db } from "../../config/firebase";

export const getCompanyUsers = async (companyId) => {
    try {
      const usersCollectionRef = collection(db, "users");
      const q = query(usersCollectionRef, where("companyID", "==", companyId));
      const usersData = await getDocs(q);
      const users = usersData.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      return users;
    } catch (err) {
      console.error("Error fetching users: ", err);
      return [];  // return an empty array on error
    }
};
